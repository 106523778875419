<template>
  <div class="hamburger-menu">
    <div class="hamburger-menu__container" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <button class="hamburger-menu__button">
        <span class="material-symbols-rounded header-content__icon">menu</span>
      </button>
      <span>menu</span>
    </div>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
        <router-link :to="route.route">{{ route.item }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
      routes: [
        {
          item       :  'DEPORTES',
          route      :  'sportsbook'
        },
        {
          item       :  'CABALLOS',
          route      :  'racebook'
        },
        {
          item       :  'APUESTAS EN VIVO',
          route      :  'live-betting'
        },
        {
          item       :  'CASINO',
          route      :  'casino'
        },
        {
          item       :  'PROPS BUILDER',
          route      :  'props-builder'
        },
      ]
    };

  },
};
</script>
