<template>
  <section class="agent-buttons">
    <img src="https://images.betimages.com/Betslip/images/luckybets21/main-logo.png" alt="wagerStore">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">Partners</a>
    <!-- <a class="primary-button primary-button__new" target="_blank" @click="openModal">Betslip Login</a> -->
  </section>
  <ModalLoginBetslip ref="modal" />
</template>

<script>
  import ModalLoginBetslip from '@/components/modal'
  export default {
    name: "AgentsButtons",
    components: {
      ModalLoginBetslip
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      const agentClassic = "http://adm.luckybets21.com/";
      const agentNew = "https://adm.luckybets21.com/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
